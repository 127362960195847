<script setup>
    import { ref, watch, onMounted, computed } from "vue";
    import { app } from 'o365-modules';
    import { getOrCreateDataObject } from 'o365-dataobject';

    const hmr = ref(localStorage.getItem('devtools.hmr') === 'true');
    const appSourceUrl = ref(null);

    const isExtarnalAppSource = computed(() => {
        return appSourceUrl.value == null
            ? false
            : new URL(appSourceUrl.value).host !== window.location.host;
    });

    const techUrl = computed(() => {
        return app.proxyRequest === 'true' ? '/nt' : '';
    });

    const props = defineProps({
        showTitle:{
            type:Boolean,
            default:false
        }
    });

    const dsApp = getOrCreateDataObject({
        "id": "o_dsApp",
        "viewName": "sviw_O365_Apps",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {
                "name": "ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null
            },
            {
                "name": "NamespaceSourceUrl",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null
            },
            {
                "name": "OriginUrl",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null
            },
            {
                "name": "OriginUrlNt",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null
            }
        ],
        "masterDataObject_ID": null,
        "masterDetailDefinition": [],
        "clientSideHandler": false,
        "maxRecords": 1,
        "dynamicLoading": false,
        "whereClause": `ID = '${app.id}'`,
        "filterString": null
    });

    watch(hmr, val => localStorage.setItem('devtools.hmr', val));

    let devMenuModal = null;
    onMounted(() => {
        devMenuModal = document.getElementById("o365-developer-menu-modal");
        devMenuModal?.addEventListener('show.bs.modal', async (event) => {
            await dsApp.load();

            if (app.proxyRequest === 'true') {
                if (dsApp.current.OriginUrl) {
                    appSourceUrl.value = `${dsApp.current.OriginUrl}/nt`;
                } else {
                    appSourceUrl.value = dsApp.current.NamespaceSourceUrl ?? '/';
                }
            } else {
                appSourceUrl.value = dsApp.current.OriginUrlNt ?? dsApp.current.NamespaceSourceUrl ?? '/';
            }
        });
    });
</script>

<template>
    <a id="o365-development-menu-toggle" v-bind="$attrs" data-bs-toggle="modal" data-bs-target="#o365-developer-menu-modal" title="Open developer menu" style="font-size: 1.125em;" href="javascript:void(0)">
        <i class="bi bi-code-slash"></i>
        <span class="ms-2" v-if="showTitle">
            {{ $t("Developer tools") }}
        </span>
    </a>
    <Teleport to="body">
        <div class="modal fade" id="o365-developer-menu-modal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5>Developer Menu</h5>
                        <div class="ms-auto">
                            <slot name="HeaderActions"></slot>
                            <div class="form-check form-switch form-check-inline" title="Hot module replacement when editing vue files. Will take effect after page reload.">
                                <input class="form-check-input" v-model="hmr" type="checkbox" id="o365-development-menu-hmr-switch">
                                <label class="form-check-label" for="o365-development-menu-hmr-switch">Vue Live Reload</label>
                            </div>
                        </div>
                        <button type="button" class="btn-close ms-2" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body row">
                        <div class="col-12 col-sm6 col-lg-4">
                            <h5 class="pb-1 pt-2 mb-0 border-bottom">Apps</h5>
                            <a class="py-1 d-block text-decoration-none" :href="`${techUrl}/appdesigner-articlelist`">Article List</a>
                            <a class="py-1 d-block text-decoration-none" :href="`${techUrl}/appdesigner?ID=${app.id}`" rel="noreferrer noopener"
                                target="_blank">Edit Current App
                            </a>
                            <a v-if="isExtarnalAppSource" class="py-1 d-block text-decoration-none" :href="`${appSourceUrl}${techUrl}/appdesigner?ID=${app.id}`" rel="noreferrer noopener"
                                target="_blank">Edit Current App at source
                            </a>
                            <a class="py-1 d-block text-decoration-none" :href="`${techUrl}/appdesigner?`" target="_blank">New App</a>                            
                        </div>
                        <div class="col-12 col-sm6 col-lg-4">
                            <h5 class="pb-1 pt-2 mb-0 border-bottom">Development</h5>
                            <a class="py-1 d-block text-decoration-none" :href="`${techUrl}/db-manager`">DB Manager</a>
                            <a class="py-1 d-block text-decoration-none" :href="`${techUrl}/sitesetup`">Site Setup</a>
                            <a class="py-1 d-block text-decoration-none" :href="`${techUrl}/code-snippets-editor`">Code Snippets editor</a>
                            <a class="py-1 d-block text-decoration-none" :href="`${techUrl}/code-search`">Code Search</a>
                            <a class="py-1 d-block text-decoration-none" :href="`${techUrl}/db-jobs`" target="_blank">Jobs</a>
                        </div>
                        <div class="col-12 col-sm6 col-lg-4">
                            <h5 class="pb-1 pt-2 mb-0 border-bottom">Debugging</h5>
                            <a class="py-1 d-block text-decoration-none" :href="`${techUrl}/loaded-assemblies`">Loaded Assemblies</a>
                            <a class="py-1 d-block text-decoration-none" :href="`${techUrl}/api/debug/cache`">Cache</a>
                            <a class="py-1 d-block text-decoration-none" :href="`${techUrl}/log`">Log</a>
                            <a class="py-1 d-block text-decoration-none" :href="`${techUrl}/api/debug/routes`">Routes</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
</template>




